body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
}

.container {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: fadeIn 1s forwards;
}

h1 {
  text-align: center;
  color: #662D91;
}

form {
  margin-bottom: 40px;
}

label {
  display: block;
  margin: 15px 0 5px;
  font-weight: bold;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-group {
  margin: 10px 0;
}

.checkbox-group input {
  margin-right: 10px;
}

button {
  background-color: #662D91;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45176e;
}

.signature-section {
  margin-top: 40px;
}

.signature-section h2 {
  color: #662D91;
  border-bottom: 2px solid #662D91;
  padding-bottom: 10px;
}

.signature-box {
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 30px;
  position: relative;
}

.button-group {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.copy-button,
.modal-button {
  background-color: #662D91;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.copy-button:hover,
.modal-button:hover {
  background-color: #45176e;
}

.signature-content {
  overflow-x: auto;
  background-color: #ffffff;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-input {
  padding: 10px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #662D91;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45176e;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s forwards;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}

.popup.open {
  opacity: 1;
  transform: scale(1);
}

.popup.close {
  opacity: 0;
  transform: scale(0.9);
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.close-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.close-button:hover {
  background-color: #0056b3;
}
